"use strict";

var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
var __spreadArray = this && this.__spreadArray || function (to, from) {
  for (var i = 0, il = from.length, j = to.length; i < il; i++, j++) to[j] = from[i];
  return to;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SlQueryRequestIterator = exports.SlQueryItem = void 0;
var SlQueryItem;
(function (SlQueryItem) {
  SlQueryItem["RULE"] = "rule";
  SlQueryItem["RULESET"] = "ruleSet";
})(SlQueryItem = exports.SlQueryItem || (exports.SlQueryItem = {}));
var SlQueryRequestIterator = /** @class */function () {
  function SlQueryRequestIterator(rootRuleSet) {
    // Initialize the stack with the root ruleSet's rules and ruleSets at depth 0
    this.stack = __spreadArray(__spreadArray([], __read(rootRuleSet.rules.map(function (rule) {
      return {
        item: rule,
        depth: 0,
        type: SlQueryItem.RULE
      };
    }))), __read(rootRuleSet.ruleSets.map(function (ruleSet) {
      return {
        item: ruleSet,
        depth: 0,
        type: SlQueryItem.RULESET
      };
    })));
  }
  SlQueryRequestIterator.prototype.next = function () {
    var _a;
    if (this.stack.length === 0) {
      return {
        done: true
      };
    } else {
      var _b = this.stack.shift(),
        item = _b.item,
        depth_1 = _b.depth,
        type = _b.type;
      if (type === SlQueryItem.RULESET) {
        item = item;
        // Push ruleSet's children with incremented depth
        (_a = this.stack).unshift.apply(_a, __spreadArray(__spreadArray([], __read(item.rules.map(function (rule) {
          return {
            item: rule,
            depth: depth_1 + 1,
            type: SlQueryItem.RULE
          };
        }))), __read(item.ruleSets.map(function (ruleSet) {
          return {
            item: ruleSet,
            depth: depth_1 + 1,
            type: SlQueryItem.RULESET
          };
        }))));
        // Return only the condition of the ruleSet
        return {
          done: false,
          value: {
            item: {
              condition: item.condition
            },
            depth: depth_1,
            type: type
          }
        };
      } else {
        item = item;
        // Return the rule as is
        return {
          done: false,
          value: {
            item: item,
            depth: depth_1,
            type: type
          }
        };
      }
    }
  };
  return SlQueryRequestIterator;
}();
exports.SlQueryRequestIterator = SlQueryRequestIterator;
