@if (filterForm && columnsSignal$()) {
	<p-overlayPanel class="toggle-column-visibility"
					hideTransitionOptions="200ms"
					showTransitionOptions="200ms"
					styleClass="filter-panel-table"
					[dismissable]="true"
					[focusOnShow]="true"
					(onHide)="onOverlayPanelHide()">

		<div class="header-wrapper sl-flexbox sl-flexbox-align-center sl-flexbox-justify-space-between">
			<div class="sl-typography-body-title">Select filters</div>
			<p-button 	[outlined]="true"
						class="sl-flexbox sl-flexbox-justify-end"
						[disabled]="totalActiveFilters === 0"
						size="small"
						label="Clear all"
						severity="secondary"
						(onClick)="clearAllFilters()">
			</p-button>
		</div>

		<form [formGroup]="filterForm">
			<p-accordion
				styleClass="sl-v1-accordion-filters scrollable"
				expandIcon="pi pi-angle-down"
				collapseIcon="pi pi-angle-up"
				[selectOnFocus]="false"
				[tabIndex]="0"
				(onClose)="onClose($event)"
				(onOpen)="onOpen($event)">
				@for (col of displayColumns; track col.field; let first = $first; ) {
					<p-accordionTab [id]="col.field" iconPos="end" [disabled]="filterDisabledState[col.field]" class="display-block mb-small">
						<ng-template pTemplate="header">
							<span class="sl-flexbox-row sl-flexbox-grow sl-flexbox-justify-space-between">
								<span class="sl-typography-body">
									{{ col.header.displayName }}
									@if (filterForm?.get(col.field)?.value) {
										/ @if (col?.metaData?.filter.type === FilterType.DATE || filterForm?.get(col.field)?.value?.rules[0]?.value.constructor.name !== "Array") {
											1
										} @else {
											{{ filterForm?.get(col.field)?.value?.rules[0]?.value?.length }}
										}
									}
								</span>
							</span>
						</ng-template>

						<div class="sl-typography-body">
							<div>
								@switch (col?.metaData?.filter.type) {

									@case(FilterType.LIST) {
										<div>
											<sl-list-filter [query]="dynamicFiltersQueryModel[col.field]" [config]="col.metaData.filter" [inputDebounce]="inputDebounceSignal$()" [formControlName]="col.field"></sl-list-filter>
										</div>
									}
									@case(FilterType.DATE) {
										<div>
											<sl-date-filter [column]="col" [formControlName]="col.field"></sl-date-filter>
										</div>
									}
									@case(FilterType.TEXT) {
										<div>
											<sl-text-filter [inputDebounce]="inputDebounceSignal$()" [column]="col" [formControlName]="col.field"></sl-text-filter>
										</div>
									}
									@default {
										Filter not supported

									}
								}
							</div>
						</div>
					</p-accordionTab>
				}
			</p-accordion>
		</form>
	</p-overlayPanel>
}

<p-button class="filter-button" styleClass="sl-v1-button" severity="filter" (onClick)="toggleOverlayPanel($event)">
	<div class="sl-flexbox-row sl-flexbox-align-center sl-flexbox-gap-4">
		<mat-icon class="mat-icon-16">filter_list</mat-icon>
		<span>Filter</span>
		@if (totalActiveFilters) {
			<span> / {{ totalActiveFilters }}</span>
		}
		<mat-icon class="mat-icon-16">arrow_drop_down</mat-icon>
	</div>
</p-button>
