"use strict";

var _a;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SlDbQueryBuilder = exports.MaxTotalsResponseSize = exports.PageSizeOptions = exports.DefaultPageSize = exports.OperatorToLabel = exports.Operator = exports.SortDirection = exports.Condition = exports.ConversionType = void 0;
var ConversionType;
(function (ConversionType) {
  ConversionType["Date"] = "Date";
})(ConversionType = exports.ConversionType || (exports.ConversionType = {}));
var Condition;
(function (Condition) {
  Condition["And"] = "AND";
  Condition["Or"] = "OR";
})(Condition = exports.Condition || (exports.Condition = {}));
var SortDirection;
(function (SortDirection) {
  SortDirection["ASC"] = "asc";
  SortDirection["DESC"] = "desc";
})(SortDirection = exports.SortDirection || (exports.SortDirection = {}));
var Operator;
(function (Operator) {
  Operator["Equals"] = "=";
  Operator["DoesNotEquals"] = "!=";
  Operator["Contains"] = "@>";
  Operator["GreaterThan"] = ">";
  Operator["GreaterThanOrEqualTo"] = ">=";
  Operator["SmallerThan"] = "<";
  Operator["SmallerThanOrEqualsTo"] = "<=";
  Operator["StartsWith"] = "^";
  Operator["EndsWith"] = "$";
  Operator["In"] = "->";
  Operator["NotIn"] = "!->";
})(Operator = exports.Operator || (exports.Operator = {}));
exports.OperatorToLabel = (_a = {}, _a[Operator.Contains] = 'Contains', _a[Operator.Equals] = 'Equals', _a[Operator.DoesNotEquals] = 'Not equals', _a[Operator.GreaterThan] = 'Greater than', _a[Operator.GreaterThanOrEqualTo] = 'Greater or equal to', _a[Operator.SmallerThan] = 'Smaller than', _a[Operator.SmallerThanOrEqualsTo] = 'Smaller or equals to', _a[Operator.StartsWith] = 'Starts with', _a[Operator.EndsWith] = 'Ends with', _a);
exports.DefaultPageSize = 50;
exports.PageSizeOptions = [10, 15, 20, 25, 30, 40, 50, 100];
exports.MaxTotalsResponseSize = 50;
var SlDbQueryBuilder;
(function (SlDbQueryBuilder) {
  SlDbQueryBuilder["SQL"] = "SQL";
  SlDbQueryBuilder["MONGO"] = "MONGO";
})(SlDbQueryBuilder = exports.SlDbQueryBuilder || (exports.SlDbQueryBuilder = {}));
