import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { SlQueryRequestBuilderWeb } from '@sealights/sl-query-builder';
import { finalize, Observable, switchMap, take, tap } from 'rxjs';

import { FilterListOptionsResponse, ListFilterConfig } from '../../sl-table/models/sl-table.model';
import { SlTableService } from '../../sl-table/services/sl-table.service';
import { FilterState } from '../models/list-filter.model';

@Injectable({
	providedIn: null
})
export class ListFilterStore<Req, Res> extends ComponentStore<FilterState<Req, Res>> {

	readonly items$ = this.select(state => state.items);

	readonly setItems = this.updater((state, options: { key: string; data: any[] }): FilterState<Req, Res> => ({
		...state,
		items: {
			...state.items,
			[options.key]: options.data
		}
	}));

	readonly setLoading = this.updater((state, loading: boolean): FilterState<Req, Res> => ({
		...state,
		loading
	}));

	readonly fetchDynamicItems = this.effect((config$: Observable<{ config: ListFilterConfig<Req, Res>; query: SlQueryRequestBuilderWeb<Req, Res> }>) => config$.pipe(
		tap(() => this.setLoading(true)),
		switchMap(({ config, query }) => this.tableService.fetchFilterData(config.dynamic.dataSourceConfig, query)
			.pipe(
				tap((response: FilterListOptionsResponse) => {
					if (response.data.totals) {
						this.setItems({
							key: config.field,
							data: response.data.totals.map(({ key, total }) => ({
								label: config.dynamic?.dataSourceConfig?.labelTransformer ? config.dynamic.dataSourceConfig.labelTransformer(key) : key,
								value: key,
								count: total
							})),
						});
					} else {
						this.setItems({
							key: config.field,
							data: response.data.list.map((item) => ({
								label: config.dynamic?.dataSourceConfig?.labelTransformer ? config.dynamic.dataSourceConfig.labelTransformer(item[config.dynamic.label]) : item[config.dynamic.label],
								value: item,
							})),
						});
					}
				}),
				finalize(() => {
					this.setLoading(false);
				})
			)))
	);

	constructor(private tableService: SlTableService) {
		super({ items: {} as FilterState<Req, Res>['items'], loading: false });
	}

	initializeOptionsIfNeeded(config: ListFilterConfig<Req, Res>, query: SlQueryRequestBuilderWeb<Req, Res>, force?: boolean): void {
		this.select(state => state.items[config.field])
			.pipe(
				take(1),
				tap(items => {
					if (force || !items || items.length === 0) {
						this.#initializeOptions(config, query);
					}
				})
			).subscribe();
	}

	#initializeOptions(config: ListFilterConfig<Req, Res>, query: SlQueryRequestBuilderWeb<Req, Res>): void {
		if (config?.dynamic) {
			this.fetchDynamicItems({ config, query });
		} else if (config?.static) {
			this.setItems({
				key: config.field as string,
				data: config.static.options.map(({ label, value }) => ({ label, value }))
			});
		}
	}
}
