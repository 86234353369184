import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, input, OnChanges, output, SimpleChanges, viewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { Condition, IslQueryRuleSet, SlQueryRequestBuilderWeb } from '@sealights/sl-query-builder';
import { Accordion, AccordionModule, AccordionTabCloseEvent, AccordionTabOpenEvent } from 'primeng/accordion';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs';

import { ListFilterStore } from '@@shared/list-filter/stores/list-filter.store';
import { createDefaultQueryBuilder, createDefaultRuleSetBuilder } from '@@shared/sl-table/models/query.model';
import { FormControlsMap, SLTableColumnDef, SLTableFilterType, SLTableFilterTypeConfigDynamic } from '@@shared/sl-table/models/sl-table.model';

import { ListFilterComponent } from '../../../list-filter/list-filter.component';
import { DateFilterComponent } from '../filters/date-filter/date-filter.component';
import { TextFilterComponent } from '../filters/text-filter/text-filter.component';

@Component({
	selector: 'sl-filter-panel',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		BadgeModule,
		FormsModule,
		ButtonModule,
		MatIconModule,
		AccordionModule,
		OverlayPanelModule,
		ReactiveFormsModule,
		ListFilterComponent,
		TextFilterComponent,
		DateFilterComponent,
	],
	providers: [ListFilterStore],
	templateUrl: './filter-panel.component.html'
})
export class FilterPanelComponent<Req, Res> implements OnChanges {
	readonly columnsSignal$ = input.required<SLTableColumnDef<Req, Res>[]>({ alias: 'columns' });
	readonly activeFiltersSignal$ = input<IslQueryRuleSet<Req, Res>[]>(null, { alias: 'activeFilters' });
	readonly inputDebounceSignal$ = input<number>(700, { alias: 'inputDebounce' });

	readonly filterChange = output<IslQueryRuleSet<Req, Res>>();

	readonly overlayPanel = viewChild<OverlayPanel>(OverlayPanel);
	readonly accordion = viewChild.required<Accordion>(Accordion);

	readonly FilterType = SLTableFilterType;
	displayColumns: SLTableColumnDef<Req, Res>[] = [];
	totalActiveFilters: number = 0;
	filterForm: FormGroup;
	dynamicFiltersQueryModel: Record<string, SlQueryRequestBuilderWeb<Req, Res>>;
	filterDisabledState: Record<string, boolean> = {};

	readonly #cdr = inject(ChangeDetectorRef);
	readonly #fb = inject(FormBuilder);
	readonly #destroyRef = inject(DestroyRef);
	readonly #slQueryRuleSetBuilderWeb = createDefaultRuleSetBuilder();
	readonly #slQueryRequestBuilderWeb = createDefaultQueryBuilder();

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.columnsSignal$) {
			this.init();
		}
	}

	init(): void {
		if (!this.columnsSignal$) {
			return;
		}
		this.#initDisplayColumns();
		this.#initDynamicFiltersQueryModel();
		this.#initializeForm();
		this.#initFilterDisableState();
		if (this.accordion()) {
			this.accordion().preventActiveIndexPropagation = true;
		}
		this.#cdr.detectChanges();
	}

	clearAllFilters(): void {
		this.filterForm.reset();
		this.filterForm.markAllAsTouched();
		this.filterForm.updateValueAndValidity();
	}

	onAccordionTabOpen($event: AccordionTabOpenEvent): void {
		$event.originalEvent.stopPropagation();
	}

	toggleOverlayPanel($event: Event): void {
		$event.preventDefault();
		$event.stopPropagation();
		this.overlayPanel().toggle($event);
	}

	onClose($event: AccordionTabCloseEvent): void {
		$event.originalEvent.stopPropagation();
		this.#cdr.detectChanges();
	}

	onOpen($event: AccordionTabOpenEvent): void {
		$event.originalEvent.stopPropagation();
		this.#cdr.detectChanges();
	}

	onOverlayPanelHide(): void {
		if (this.accordion()) {
			this.accordion().preventActiveIndexPropagation = true;
		}
		this.#cdr.detectChanges();
	}

	#initializeForm(): void {
		this.totalActiveFilters = 0;
		const formControls = this.#initFormControlsBasedOnDisplayColumns();
		this.filterForm = this.#fb.group(formControls);
		this.#calculateTotalActiveFilters();
		this.#listenToFilterFormChanges();
		this.#listenToFormControlChanges();
	}

	#getDynamicDisplayedColumns(): SLTableColumnDef<Req, Res>[] {
		return this.displayColumns.filter(column => !!(column?.metaData?.filter as SLTableFilterTypeConfigDynamic<Req, Res>)?.dynamic);
	}

	#initDynamicFiltersQueryModel(): void {
		this.dynamicFiltersQueryModel = this.#getDynamicDisplayedColumns().reduce((dataObject, col) => {
			const defaultQuery = (col.metaData.filter as SLTableFilterTypeConfigDynamic<Req, Res>).dynamic.dataSourceConfig.defaultQuery;
			const query = defaultQuery ? SlQueryRequestBuilderWeb.build<Req, Res>(defaultQuery) : createDefaultQueryBuilder<Req, Res>();
			query.withCountsFor(col.field as keyof Res);
			dataObject[col.field as string] = query;
			return dataObject;
		}, {});
	}

	#initFormControlsBasedOnDisplayColumns(): FormControlsMap<Req, Res> {
		return this.displayColumns.reduce((acc, column) => {
			acc[column.field] = new FormControl(this.activeFiltersSignal$()?.find((ruleSet: IslQueryRuleSet<Req, Res>) => ruleSet?.rules[0]?.field === column?.field));
			return acc;
		}, {} as FormControlsMap<Req, Res>);
	}

	#listenToFormControlChanges(): void {
		Object.keys(this.filterForm.controls).forEach(controlName => {
			this.filterForm.controls[controlName].valueChanges.subscribe((value) => {
				const controlsToRecalculate = this.#getDynamicDisplayedColumns()
					.filter(column => column.field !== controlName)
					.filter((column) => column.dependentOn?.includes(controlName) || !column.dependentOn)
					.map(column => column.field);

				controlsToRecalculate.forEach((columnName) => {
					this.dynamicFiltersQueryModel[columnName].ruleSetBuilder.deleteRules(controlName as keyof Req | keyof Res);

					if (value) {
						this.dynamicFiltersQueryModel[columnName].ruleSetBuilder.withRuleSet(this.#slQueryRuleSetBuilderWeb.buildRuleSet(value, this.#slQueryRequestBuilderWeb));
					}

					this.dynamicFiltersQueryModel[columnName] = SlQueryRequestBuilderWeb.build(this.dynamicFiltersQueryModel[columnName].done()); // in order to trigger ngOnChanges
				});
			});
		});
	}

	#listenToFilterFormChanges(): void {
		this.filterForm.valueChanges
			.pipe(
				takeUntilDestroyed(this.#destroyRef),
				distinctUntilChanged(),
				debounceTime(this.inputDebounceSignal$()),
				tap((value: Record<string, IslQueryRuleSet<Req, Res>>) => {
					this.#emitFilterChange(value);
					this.#initFilterDisableState();
				}))
			.subscribe();
	}

	#emitFilterChange(formValue: Record<string, IslQueryRuleSet<Req, Res>>): void {
		const ruleSets: IslQueryRuleSet<Req, Res>[] = [];
		this.totalActiveFilters = 0;
		Object.keys(formValue).forEach(field => {
			const ruleSet: IslQueryRuleSet<Req, Res> = formValue[field];
			if (ruleSet) {
				ruleSets.push(ruleSet);
				this.totalActiveFilters += this.#calculateActiveFilter(ruleSet, field);
			}
		});
		const combinedRuleSet = SlQueryRequestBuilderWeb.createRuleSetBuilder<Req, Res>(Condition.And).done();
		combinedRuleSet.ruleSets = ruleSets;
		this.filterChange.emit(combinedRuleSet);
	}

	#isDateFilter(field: string): boolean {
		return this.displayColumns.find((col) => col.field === field)?.metaData?.filter?.type === SLTableFilterType.DATE;
	}

	#initDisplayColumns(): void {
		this.displayColumns = this.columnsSignal$()
			.filter((col) => col.isFilterable)
			.sort((a, b) => { // setting date filters first
				if (a.metaData?.filter?.type === SLTableFilterType.DATE && b.metaData?.filter?.type !== SLTableFilterType.DATE) {
					return -1;
				}
				if (a.metaData?.filter?.type !== SLTableFilterType.DATE && b.metaData?.filter?.type === SLTableFilterType.DATE) {
					return 1;
				}
				return 0;
			});
	}

	#calculateTotalActiveFilters(): void {
		Object.keys(this.filterForm.value as Record<string, IslQueryRuleSet<Req, Res>>).forEach((field) => {
			const ruleSet: IslQueryRuleSet<Req, Res> = this.filterForm.value[field] as IslQueryRuleSet<Req, Res>;
			if (ruleSet) {
				this.totalActiveFilters += this.#calculateActiveFilter(ruleSet, field);
			}
		});
		this.#cdr.detectChanges();
	}

	#calculateActiveFilter(ruleSet: IslQueryRuleSet<Req, Res>, field: string): number {
		let totalRules = 0;

		if (this.#isDateFilter(field)) {
			return 1;
		}

		if(!ruleSet?.rules) {
			return 0;
		}

		for (const rule of ruleSet.rules) {
			if (!rule.value) {
				totalRules += 0;
				continue;
			}

			if (Array.isArray(rule.value)) {
				totalRules += rule.value.length;
			} else {
				totalRules += 1;
			}
		}

		return totalRules;
	}

	#initFilterDisableState(): void {
		this.displayColumns.forEach(column => {
			const isDisabled = column.dependentOn?.some(dependency => !this.filterForm.controls[dependency].value);
			if (isDisabled && this.filterForm.controls[column.field].value) {
				this.filterForm.controls[column.field].setValue(undefined);
			}
			this.filterDisabledState[column.field] = isDisabled;
		});
	}
}
