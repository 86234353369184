import { ChangeDetectionStrategy, Component, DestroyRef, inject, input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormControl, FormGroup, FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule, ValidationErrors, Validator } from '@angular/forms';
import { Condition, IslQueryRuleSet, Operator, SlQueryRequestBuilderWeb } from '@sealights/sl-query-builder';
import { InputTextModule } from 'primeng/inputtext';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs';

import { TextFilterConfig } from '@@shared/sl-table/models/sl-table.model';

import { BaseFilterDirective } from '../base-filter/base-filter.directive';

@Component({
	selector: 'sl-text-filter',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		ReactiveFormsModule,
		FormsModule,
		InputTextModule
	],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: TextFilterComponent,
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: TextFilterComponent,
			multi: true,
		}
	],
	templateUrl: './text-filter.component.html',
	styleUrl: './text-filter.component.scss'
})
export class TextFilterComponent<Req, Res> extends BaseFilterDirective<Req, Res, TextFilterConfig> implements OnInit, Validator {
	readonly inputDebounceSignal$ = input<number>(700, { alias: 'inputDebounce' });

	readonly form: FormGroup = new FormGroup({
		search: new FormControl(),
		operator: new FormControl()
	});

	readonly #destroyRef = inject(DestroyRef);

	#onChange: (value: IslQueryRuleSet<Req, Res>) => void;
	#onTouched: () => void;

	validate(control: AbstractControl<any, any>): ValidationErrors {
		return null;
	}

	writeValue(ruleSet: IslQueryRuleSet<Req, Res>): void {
		this.form.setValue({
			search: ruleSet ? ruleSet.rules[0].value as string : null,
			operator: ruleSet ? ruleSet.rules[0].operator : Operator.Contains
		}, { emitEvent: false });
	}

	registerOnChange(fn: (value: IslQueryRuleSet<Req, Res> | null) => void): void {
		this.#onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.#onTouched = fn;
	}

	ngOnInit(): void {
		this.#onFilter();
	}

	#onFilter(): void {
		this.form.valueChanges.pipe(
			takeUntilDestroyed(this.#destroyRef),
			distinctUntilChanged(),
			debounceTime(this.inputDebounceSignal$()),
			tap(({ search, operator }: { search: string; operator: Operator }) => {
				if (!search) {
					this.#onChange(null);
					return;
				}
				const ruleSet: IslQueryRuleSet<Req, Res> = SlQueryRequestBuilderWeb.createRuleSetBuilder<Req, Res>(Condition.And).done();
				ruleSet.rules = [{ field: this.columnSignal$().field, operator, value: search }];
				this.#onChange(ruleSet);
			})
		).subscribe();
	}
}
