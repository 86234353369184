"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QueryDataStatus = void 0;
var QueryDataStatus;
(function (QueryDataStatus) {
  QueryDataStatus["NoData"] = "NoData";
  QueryDataStatus["InProgress"] = "InProgress";
  QueryDataStatus["OK"] = "OK";
  QueryDataStatus["Error"] = "Error";
})(QueryDataStatus = exports.QueryDataStatus || (exports.QueryDataStatus = {}));
