import { AsyncPipe, DecimalPipe, NgTemplateOutlet } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, input, OnChanges, OnInit, output, signal, SimpleChanges, viewChild } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { AbstractControl, FormControl, FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule, ValidationErrors, Validator } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Condition, IslQueryRuleSet, Operator, SlQueryRequestBuilderWeb } from '@sealights/sl-query-builder';
import { CheckboxModule } from 'primeng/checkbox';
import { Dropdown, DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { Listbox, ListboxChangeEvent, ListboxModule } from 'primeng/listbox';
import { MultiSelect, MultiSelectModule } from 'primeng/multiselect';
import { TooltipModule } from 'primeng/tooltip';
import { debounceTime, distinctUntilChanged, map, Observable, tap } from 'rxjs';

import { Action } from '@@core/models/action.model';
import { ActionPropertyResolverPipe } from '@@shared/actionPropertyResolver/action-property-resolver.pipe';
import { ListFilterStore } from '@@shared/list-filter/stores/list-filter.store';
import { SearchBoxComponent } from '@@shared/search-box';
import { FilterDisplayOption, ListFilterConfig } from '@@shared/sl-table/models/sl-table.model';
import { SlideToggleComponent } from '@@shared/slide-toggle';

import { BaseFilterDirective } from '../sl-table/components/filters/base-filter/base-filter.directive';
import { ToggleChangedEvent } from './models/list-filter.model';

@Component({
	selector: 'sl-list-filter',
	templateUrl: './list-filter.component.html',
	styleUrl: './list-filter.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		NgTemplateOutlet,
		DecimalPipe,
		AsyncPipe,
		MultiSelectModule,
		TooltipModule,
		ListboxModule,
		DropdownModule,
		FormsModule,
		SearchBoxComponent,
		InputTextModule,
		ReactiveFormsModule,
		MatIconModule,
		CheckboxModule,
		MatSlideToggleModule,
		SlideToggleComponent,
		InputSwitchModule,
		ActionPropertyResolverPipe,
	],
	providers: [
		ListFilterStore,
		ActionPropertyResolverPipe,
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: ListFilterComponent,
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: ListFilterComponent,
			multi: true,
		}
	],
})
export class ListFilterComponent<Req, Res> extends BaseFilterDirective<Req, Res, ListFilterConfig<Req, Res>> implements OnInit, OnChanges, AfterViewInit, Validator {
	readonly inputDebounceSignal$ = input<number>(700, { alias: 'inputDebounce' });
	readonly configSignal$ = input<ListFilterConfig<Req, Res>>(null, { alias: 'config' });
	readonly querySignal$ = input<SlQueryRequestBuilderWeb<Req, Res>>(null, { alias: 'query' });


	readonly clearAllSignal$ = output<void>({ alias: 'onClearAll' });
	readonly labelToggleChanged = output<ToggleChangedEvent>();

	readonly listBox = viewChild<Listbox>('listbox');
	readonly dropdown = viewChild<Dropdown>('dropdown');
	readonly multiSelect = viewChild<MultiSelect>('multiselect');

	items$: Observable<FilterDisplayOption[]>;
	selectedItems: FilterDisplayOption[] = [];
	selectAll = false;
	readonly searchControl = new FormControl();
	disabled: boolean = false;
	showClear = false;
	readonly _querySignal$ = signal<SlQueryRequestBuilderWeb<Req, Res>>(null);

	readonly loadingSignal$ = toSignal(inject(ListFilterStore<Req, Res>).select(state => state.loading));
	readonly #filterPanelListboxStore = inject(ListFilterStore<Req, Res>);
	readonly #destroyRef = inject(DestroyRef);
	readonly #cdr = inject(ChangeDetectorRef);
	readonly #actionPropertyResolverPipe = inject(ActionPropertyResolverPipe);

	#initialRuleSet: IslQueryRuleSet<Req, Res> = null;

	writeValue(ruleSet: IslQueryRuleSet<Req, Res>): void {
		if (ruleSet && ruleSet.rules) {
			this.#initialRuleSet = ruleSet;
			this.#cdr.detectChanges();
		} else {
			this.clearFilter(false);
		}
	}

	registerOnChange(fn: any): void {
		this.#onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.#onTouched = fn;
	}

	validate(control: AbstractControl<any, any>): ValidationErrors {
		return null;
	}

	onDropdownChange(event: FilterDisplayOption): void {
		this.selectedItems = [event];
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
		this.#cdr.detectChanges();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.querySignal$) {
			this._querySignal$.set(this.querySignal$());
			if (!changes.querySignal$.firstChange) {
				this.#resetOptions();
			}
		}
	}

	ngOnInit(): void {
		if (!this.querySignal$() && this.configSignal$().dynamic) {
			this._querySignal$.set(SlQueryRequestBuilderWeb.build(this.configSignal$().dynamic.dataSourceConfig.defaultQuery));
		}

		this.items$ = this.#filterPanelListboxStore.select(state => state.items[this.configSignal$().field] || []).pipe(
			tap(items => this.#initFirstItemWhenNeeded(items)),
			tap(items => this.#initSelectedItems(items)),
			map(items => this.#sortbySelectedItemsFirst(items)),
			map(items => !this.loadingSignal$() ? this.#addSelectedItemsIfNotExists(items) : items),
			tap(items => this.#reselectItems(items))
		);

		this.onFilter();
	}

	ngAfterViewInit(): void {
		if (!this.disabled) {
			this.#filterPanelListboxStore.initializeOptionsIfNeeded(this.configSignal$(), this._querySignal$());
		}
	}

	onSelectAllChange(event: { originalEvent: Event; checked: boolean }, items: FilterDisplayOption[]): void {
		event.originalEvent.stopPropagation();
		this.selectedItems = event.checked ? items : [];
		this.selectAll = event.checked;
		const ruleSet = this.#processRuleSet(this.selectedItems, this.selectAll);
		this.#emitEvent(ruleSet);
	}

	onSelectionChange(event: ListboxChangeEvent, items: FilterDisplayOption[]): void {

		if (Array.isArray(event.value)) {
			this.selectedItems = event.value as FilterDisplayOption[];
		} else {
			if (this.selectedItems[0]?.value === event.value?.value) {
				this.selectedItems = [];
			} else {
				this.selectedItems = [event.value as FilterDisplayOption];
			}
		}
		if (event.value) {
			this.selectAll = this.searchControl.value ? false : (event.value as FilterDisplayOption[]).length === items.length;
		}

		const ruleSet = this.#processRuleSet(this.selectedItems, this.selectAll);
		this.#emitEvent(ruleSet);
	}

	selectOnly(): void {
		this.clearFilter(false);
	}

	onLabelToggleChange({ checked }): void {
		this.labelToggleChanged.emit({ field: this.configSignal$().field, checked: checked as boolean });
	}

	onFilter(): void {
		this.searchControl.valueChanges.pipe(
			tap(() => {
				this.#filterPanelListboxStore.setLoading(true);
				this.#filterPanelListboxStore.setItems({ key: this.configSignal$().field, data: [] });
			}),
			takeUntilDestroyed(this.#destroyRef),
			distinctUntilChanged(),
			debounceTime(this.inputDebounceSignal$()),
			tap((value: string) => {
				this.#filterItems(value);
			})
		).subscribe();
	}

	clearFilter(emitEvent: boolean): void {
		this.listBox()?.uncheckAll();
		this.multiSelect()?.uncheckAll();
		this.dropdown()?.updateSelectedOption(null);

		this.listBox()?.cd.detectChanges();
		this.multiSelect()?.cd.detectChanges();
		this.dropdown()?.cd.detectChanges();

		this.selectAll = false;
		this.selectedItems = [];
		this.#initialRuleSet = null;

		if (emitEvent) {
			this.#emitEvent(null);
			this.clearAllSignal$.emit();
		}
		this.#cdr.detectChanges();
	}

	onActionClick(event: Event, action: Action, item: FilterDisplayOption): void {
		event.stopPropagation();
		const { disabled } = this.#actionPropertyResolverPipe.transform(action, item);
		if (disabled) {
			return;
		}
		action.action(event, item);
	}

	#addSelectedItemsIfNotExists(items: FilterDisplayOption[]): FilterDisplayOption[] {
		if (this.configSignal$().dynamic) {
			const selectedItemsNotInOptionItems = this.selectedItems.filter(selectedItem => !items.some((optionItem) => selectedItem.value === optionItem.value));
			return selectedItemsNotInOptionItems.concat(items);
		}
		return items;
	}
	#resetOptions(): void {
		this.#filterPanelListboxStore.initializeOptionsIfNeeded(this.configSignal$(), this._querySignal$(), true);
	}

	#initFirstItemWhenNeeded(items: FilterDisplayOption[]): void {
		if (!this.selectedItems.length && items.length && this.configSignal$().selectFirstOptionIfEmpty && this.configSignal$().defaultValue === undefined) {
			this.writeValue(SlQueryRequestBuilderWeb.createRuleSetBuilder<Req, Res>(Condition.And).withRule(this.configSignal$().field, Operator.Equals, items[0].value).done());
			this.#emitEvent(this.#initialRuleSet);
		}
	}

	#initSelectedItems(items: FilterDisplayOption[]): void {
		if (this.#initialRuleSet && this.#initialRuleSet?.rules.length && !this.selectedItems?.length) {
			if (Array.isArray(this.#initialRuleSet.rules[0]?.value)) {
				this.selectedItems = items.filter(item => (this.#initialRuleSet.rules[0]?.value as string[]).some(rule => rule === item.value));
			} else {
				this.selectedItems = items.filter(item => this.#initialRuleSet.rules[0]?.value === item.value);
			}
			this.selectAll = this.selectedItems.length === items.length;
		}
	}

	#sortbySelectedItemsFirst(items: FilterDisplayOption[]): FilterDisplayOption[] {
		if (this.configSignal$()?.ui?.displayMode === 'dropdown') {
			return items;
		}
		return items.sort((a, b) => {
			const aIsSelected = (this.selectedItems)?.map((x) => x.value).includes(a.value);
			const bIsSelected = (this.selectedItems)?.map((x) => x.value).includes(b.value);

			if (aIsSelected && !bIsSelected) {
				return -1;
			} else if (!aIsSelected && bIsSelected) {
				return 1;
			} else {
				return 0;
			}
		});
	}

	#processRuleSet(selected: FilterDisplayOption[], selectAll: boolean): IslQueryRuleSet<Req, Res> {
		if (selected.filter((item) => !!item).length === 0) {
			return null;
		}
		if (selectAll) {
			return this.#handleSelectAllCase(selected);
		} else {
			const ruleSet = this.#createRuleSetFromSelectedItems(selected);
			return ruleSet;
		}
	}

	#handleSelectAllCase(selected: FilterDisplayOption[]): IslQueryRuleSet<Req, Res> {
		if (!this.configSignal$().preventResetFilterOnSelectAll) {
			return null;
		}
		const ruleSet = this.#createRuleSetFromSelectedItems(selected);
		return ruleSet;
	}

	#createRuleSetFromSelectedItems(selected: FilterDisplayOption[]): IslQueryRuleSet<Req, Res> {
		const requestBuilder = SlQueryRequestBuilderWeb.createRuleSetBuilder<Req, Res>(Condition.And);
		if (selected.length > 1) {
			requestBuilder.withRule(this.configSignal$().field, Operator.In, selected.map(selectedItem => selectedItem.value)).done();
		} else {
			requestBuilder.withRule(this.configSignal$().field, Operator.Equals, selected[0].value).done();
		}
		return requestBuilder.done();
	}

	#filterItems(searchTerm: string): void {
		if (this.configSignal$()?.dynamic) {
			this.#handleDynamicSearch(searchTerm);
		} else {
			this.#handleStaticSearch(searchTerm);
		}
	}

	#handleDynamicSearch(searchTerm: string): void {
		this._querySignal$().ruleSetBuilder.deleteRules(this.configSignal$().field);

		if (searchTerm) {
			this._querySignal$().ruleSetBuilder.withRule(this.configSignal$().field, Operator.Contains, searchTerm);
		}
		this.#filterPanelListboxStore.fetchDynamicItems({ config: this.configSignal$(), query: this._querySignal$() });
	}

	#handleStaticSearch(searchTerm: string): void {
		this.#filterPanelListboxStore.setItems({
			key: this.configSignal$().field,
			data: this.configSignal$().static.options.filter((option: FilterDisplayOption) => option.label.toLowerCase().includes(searchTerm.toLowerCase()))
		});

		this.#filterPanelListboxStore.setLoading(false);
	}

	#reselectItems(items: FilterDisplayOption[]): void {
		if (this.configSignal$()?.dynamic) {
			const selectedItemValues = new Set(this.selectedItems.map(selectedItem => selectedItem.value));
			this.selectedItems = items.filter(item => selectedItemValues.has(item.value));
		}
	}

	#emitEvent(ruleSet: IslQueryRuleSet<Req, Res>): void {
		this.#onChange(this.configSignal$().emitEventAs === 'query' ? ruleSet : this.selectedItems);
	}

	#onChange: (value: any) => void = () => { };
	#onTouched: () => void = () => { };
}
