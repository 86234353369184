
import { TemplateRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IslQueryRequest, PageSizeOptions } from '@sealights/sl-query-builder';
import { MenuItem } from 'primeng/api/menuitem';

import { Action } from '@@core/models/action.model';
import { PagedResponseWrapper, QueryDataStatus } from '@@shared/common/models/api.model';

import { SLLocalStorageKey } from '../../local-storage/models/SLTableStorageKey';
import { createDefaultQueryBuilder } from './query.model';

export type StringKeys<T> = Extract<keyof T, string>;
export interface SLTableConfig<Req, Res> {
	id: SLTableIds;
	className?: string;
	dataSourceConfig: ApiConfig<Req, Res>;
	columnConfig?: SLTableColumnConfig<Req, Res>;
	features?: SLTableFeature<Res>;
	updateColumnsOnChange?: boolean;
}

export enum DataSourceMode {
	DYNAMIC = 'dynamic',
	LOCAL_STORAGE = 'local-storage'
}

export interface ApiConfig<Req, Res> {
	mode?: DataSourceMode;
	localStorageKey?: SLLocalStorageKey;
	apiEndpoint?: string;
	defaultQuery?: IslQueryRequest<Req, Res>; // responsible for searching, sorting and filtering with the BE
	responseTransformer?: (apiResponse: any) => any;
}

export interface SLTableColumnConfig<Req, Res> {
	columnDefs?: SLTableColumnDef<Req, Res>[];
	ignoredColumns?: string[]; // list of fields that should be excluded when the table is rendered
}

export interface SLTableColumnDef<Req extends Record<string, any>, Res extends Record<string, any>, F extends SLTableFilterTypeConfig<Req, Res> = SLTableFilterTypeConfig<Req, Res>, O extends SLTableOptionsTypeConfig = SLTableOptionsTypeConfig> {
	field: StringKeys<Res>;
	header?: HeaderDef;
	type?: SLTableColumnType;
	isVisible?: boolean;
	isDisabled?: boolean;
	isToggable?: boolean;
	isSortable?: boolean;
	isFilterable?: boolean;
	isSticky?: boolean;
	isResizable?: boolean;
	order?: number;
	style?: { [key: string]: any; };
	dependentOn?: string[];
	metaData?: {
		filter?: F;
		options?: O;
	};
}
export interface DynamicFilterConfig<Req, Res> {
	dataSourceConfig: ApiConfig<Req, Res> & { labelTransformer?: <T>(apiResponse: T) => T; };
	label: string;
	value: keyof Req | keyof Res;
}

export interface HeaderDef {
	displayName: string;
	icon?: string;
	tooltip?: string | TemplateRef<any>;
	hideDisplayName?: boolean;
}

export interface SLTableFeature<Res> {
	filterDebounceTime?: number; // Hack for storybook display
	enableRowSelection?: boolean;
	enableHeaderSelection?: boolean;
	disableInfiniteScroll?: boolean;
	enableActionsColumn?: boolean;
	rowExpansion?: SLTableRowExpansion;
	headerActions?: SLTableHeaderAction[];
	searchBox?: {
		global?: boolean;
		fieldToSearch?: StringKeys<Res>;
		placeholder?: string;
	};
	stickyColumns?: boolean;
	sorting?: boolean;
	filtering?: boolean;
	columnsOrdering?: boolean;
	resizableColumns?: boolean;
	hideSettings?: boolean;
	pageSizeOptions?: PageSizeOptions[];
	hideHeader?: boolean;
	removeFilterStateOnDestroy?: boolean;
}

export interface SLTableRowExpansion {
	enable: boolean;
	dataKey: string; // mandatory when using the row expansion. should equal to a field from your data that uniquely identifies each row
}

export interface SLTableHeaderAction {
	id?: string;
	title: string;
	icon?: string;
	disabled?: boolean;
	hidden?: boolean;
	tooltip?: string | TemplateRef<any>;
	disabledTooltip?: string;
	tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
	disableFunc?: <Res>(tableData: TableData<Res>) => boolean;
	action?: (event?: Event) => void;
	items?: MenuItem[];
}

export interface SLTableSelection {
	all: boolean;
	selection: any[];
	actions?: SLTableHeaderAction[];
}

export interface FilterDisplayOption {
	label: string;
	value: string | number | boolean;
	count?: number;
}

export interface BaseFilterConfig {
	type: SLTableFilterType;
}

export interface ListFilterConfig<Req extends Record<string, any>, Res extends Record<string, any>> extends BaseFilterConfig {
	type: SLTableFilterType.LIST;
	defaultValue?: string;
	field?: StringKeys<Res>;
	dependsOn?: string[];
	preventResetFilterOnSelectAll?: boolean;
	dynamic?: DynamicFilterConfig<Req, Res>;
	static?: {
		options: FilterDisplayOption[];
	};
	selectFirstOptionIfEmpty?: boolean;
	emitEventAs: 'raw' | 'query';
	ui?: {
		displayMode: 'dropdown' | 'list' | 'multiselect';
		placeholder: string;
		label?: string;
		inputDebounce?: number;
		multiSelect?: boolean;
		filter: boolean;
		isFirst?: boolean;
		actions?: Action[];
		hideClear?: boolean;
	};
	labelToggle?: {
		default: boolean;
		label?: string;
		icon?: string;
		iconTooltip?: string;
	};
}

export interface TextFilterConfig extends BaseFilterConfig {
	type: SLTableFilterType.TEXT;
	// Specific properties for text filter
}

export interface NumberFilterConfig extends BaseFilterConfig {
	type: SLTableFilterType.NUMBER;
	// Specific properties for number filter
}

export interface DateFilterConfig extends BaseFilterConfig {
	type: SLTableFilterType.DATE;
	// Specific properties for date filter
}

export type SLTableFilterTypeConfig<Req, Res> = DateFilterConfig | ListFilterConfig<Req, Res> | TextFilterConfig | NumberFilterConfig; // add other specific filter types here
export type SLTableFilterTypeConfigDynamic<Req, Res> = ListFilterConfig<Req, Res>; // add other specific filter types here

export interface DateOptionsConfig {
	from_field: string;
	to_field: string;
}

export type SLTableOptionsTypeConfig = DateOptionsConfig;

export interface SetupTableResponse<Req, Res> {
	tableData: TableResponse<Res>;
	columns: SLTableColumnDef<Req, Res>[];
}

export interface TableResponse<Res> {
	data: TableData<Res>;
}

export interface TableData<Res> {
	total: number;
	next: number;
	previous: number;
	status?: QueryDataStatus;
	list: Res[];
}

export interface FilterListOptionsResponse {
	data: {
		totals?: {
			key: string;
			total?: number;
		}[];
		list?: any;
	};
}

// ENUMS //

export enum SLTableIds {
	DEMO_TABLE = 'demo-table',
	TEST_METRICS = 'test_metrics',
	TIA_V3_FILTERS_TABLE = 'tia-v3-filters-table',
	TEST_MANAGEMENT = 'tia-test-management',
	TEST_DEPENDENCIES = 'tia-test-dependencies',
	DASHBOARD_V2_TABLE = 'dashboard-v2-table',
	TSR_TABLE = 'tsr-table',
	LAB_MAPPING_TABLE = 'lab-mapping-table',
	TIA_SETTINGS_TABLE = 'tia-settings-table',
	AUT = 'AUT-table'
}

export enum SLTableColumnType {
	TEXT = 'text',
	TIME = 'time',
	DATE = 'date',
	DATE_TIME = 'date-time',
	NUMBER = 'number',
	CUSTOM = 'custom',
	DATE_RANGE = 'date-range',
	LIST = 'list',
	ENUM = 'enum',
	DURATION = 'duration'
}

export enum SLTableFilterType {
	TEXT = 'text',
	DATE = 'date',
	NUMBER = 'number',
	LIST = 'list',
}

export enum SLTableEmptyStateReason {
	NoData = 'noData',
	NoDataDueToFilters = 'noDataDueToFilters'
}

export const getDefaultColumnStyle = <Req, Res>(columnDefs: SLTableColumnDef<Req, Res>[], style?: object): object => {
	if (style) {
		return style;
	}

	return {
		width: (100 / columnDefs.length).toFixed(0) + '%',
		minWidth: '100px'
	};
};

export const createSLTableColumnDef = <Req, Res, F extends SLTableFilterTypeConfig<Req, Res>, O extends SLTableOptionsTypeConfig = SLTableOptionsTypeConfig>(key: StringKeys<Res>, displayName: string, type: SLTableColumnType, filterType: F, typeOptions: O): SLTableColumnDef<Req, Res, F, O> => (
	{
		field: key,
		header: {
			displayName,
			icon: null,
			tooltip: null
		},
		isFilterable: true,
		isSortable: true,
		isSticky: false,
		isVisible: true,
		order: 0,
		style: null,
		type,
		metaData: {
			filter: filterType,
			options: typeOptions
		}
	}
);

export const createInitialSLTableConfig = <Req, Res>(id: SLTableIds): SLTableConfig<Req, Res> => ({
	dataSourceConfig: {
		apiEndpoint: null,
		defaultQuery: createDefaultQueryBuilder().done(),
		responseTransformer: null,
	},
	id,
	className: null,
	columnConfig: {
		ignoredColumns: [],
		columnDefs: []
	},
	features: {
		filterDebounceTime: 700,
		disableInfiniteScroll: false,
		enableActionsColumn: true,
		enableRowSelection: false,
		columnsOrdering: true,
		filtering: true,
		resizableColumns: true,
		searchBox: {
			global: true
		},
		sorting: true,
		stickyColumns: false,
		rowExpansion: null,
		headerActions: [],
	},
});

export type FormControlsMap<Req, Res> = {
	[K in keyof Req | keyof Res]: FormControl;
};

export const createBasicFilterDataTransformer = <Res>(field: keyof Res) =>
	(response: PagedResponseWrapper<Res>): FilterListOptionsResponse =>
		({ data: { totals: response.data.list.map(data => ({ key: data[field] as string })) } });

const DEFAULT_PAGE_SIZE_OPTIONS: PageSizeOptions[] = [10, 15, 20, 25, 30, 40, 50];
