import { Pipe, PipeTransform } from '@angular/core';

import { Action } from '@@core/models/action.model';

@Pipe({
	name: 'actionPropertyResolver',
	standalone: true
})
export class ActionPropertyResolverPipe implements PipeTransform {

	transform(action: Action, item: any): { disabled: boolean; tooltip: string } {

		const disabled = typeof action.disabled === 'function' ? action.disabled(item) : !!action.disabled;
		const tooltip = typeof action.tooltip === 'function' ? action.tooltip(item) : action.tooltip || '';

		return { disabled, tooltip };
	}

}
