"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SlQueryRuleSetBuilderWeb = exports.SlQueryRequestBuilderWeb = void 0;
var sl_query_request_builder_1 = require("../core/sl-query-request-builder");
var SlQueryRequestBuilderWeb = /** @class */function (_super) {
  __extends(SlQueryRequestBuilderWeb, _super);
  function SlQueryRequestBuilderWeb() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  SlQueryRequestBuilderWeb.build = function (request) {
    return sl_query_request_builder_1.SlQueryRequestBuilder.buildPrivate(request, new SlQueryRequestBuilderWeb());
  };
  SlQueryRequestBuilderWeb.prototype.getNewRuleSetBuilder = function (condition) {
    return new SlQueryRuleSetBuilderWeb(condition);
  };
  SlQueryRequestBuilderWeb.createRuleSetBuilder = function (condition) {
    return new SlQueryRuleSetBuilderWeb(condition);
  };
  return SlQueryRequestBuilderWeb;
}(sl_query_request_builder_1.SlQueryRequestBuilder);
exports.SlQueryRequestBuilderWeb = SlQueryRequestBuilderWeb;
var SlQueryRuleSetBuilderWeb = /** @class */function (_super) {
  __extends(SlQueryRuleSetBuilderWeb, _super);
  function SlQueryRuleSetBuilderWeb(condition) {
    return _super.call(this, condition) || this;
  }
  SlQueryRuleSetBuilderWeb.prototype.buildRuleSet = function (ruleSet, builder) {
    return _super.prototype.buildRuleSet.call(this, ruleSet, builder);
  };
  SlQueryRuleSetBuilderWeb.prototype.buildRule = function (field, operator, value, conversionType) {
    return __assign({
      operator: operator,
      field: field,
      value: value
    }, conversionType ? {
      conversionType: conversionType
    } : {});
  };
  return SlQueryRuleSetBuilderWeb;
}(sl_query_request_builder_1.SlQueryRuleSetBuilder);
exports.SlQueryRuleSetBuilderWeb = SlQueryRuleSetBuilderWeb;
