@if (items$ | async; as items) {
	<div class="generic-listbox-wrapper sl-flexbox-column sl-flexbox-gap-4">
		@if (configSignal$().ui.label) {
			<label class="label-toggle sl-flexbox-row sl-flexbox-justify-space-between sl-flexbox-align-center sl-typography-label">
				{{ configSignal$().ui.label }}
				@if (configSignal$()?.labelToggle) {
					<span class="sl-flexbox-row sl-flexbox-align-center sl-flexbox-gap-8">
						<mat-icon class="mat-icon-16" [pTooltip]="configSignal$().labelToggle.iconTooltip" tooltipPosition="bottom" tooltipStyleClass="sl-v1-tooltip">{{ configSignal$().labelToggle.icon }}</mat-icon>
						{{ configSignal$().labelToggle.label }}
					</span>
					<p-inputSwitch [ngModel]="configSignal$().labelToggle.default" class="input-switch-small" (onChange)="onLabelToggleChange($event)"></p-inputSwitch>
				}
			</label>
		}
		<div class="sl-flexbox-column">
		@switch (configSignal$()?.ui?.displayMode) {
			@case ('list') {
				<p-listbox #listbox
					styleClass="new-design no-border sl-v1"
					optionLabel="label"
					[listStyle]="{ 'max-height': '220px' }"
					[options]="items"
					[ngModel]="selectedItems"
					[filter]="configSignal$()?.ui.filter"
					[multiple]="configSignal$()?.ui.multiSelect"
					[checkbox]="true"
					[showToggleAll]="true"
					(onClear)="clearFilter(true)"
					(onSelectAllChange)="onSelectAllChange($event, items)"
					(onChange)="onSelectionChange($event, items)">
					<ng-template pTemplate="filter">
						<span class="p-input-icon-right sl-width-100">
							<i class="pi {{!!loadingSignal$() ? 'pi-spin pi-spinner' : 'pi-search'}}"></i>
							<input class="sl-v1" placeholder="Search..." type="text" pInputText [formControl]="searchControl" />
						</span>
					</ng-template>
					<ng-template let-item pTemplate="item">
						<ng-container *ngTemplateOutlet="itemDisplay; context:{ item, items }"></ng-container>
					</ng-template>
					<ng-template pTemplate="empty">
						<ng-container *ngTemplateOutlet="emptyMessage"></ng-container>
					</ng-template>
				</p-listbox>
			}

		@case ('dropdown') {
			<p-dropdown #dropdown
				[styleClass]="'sl-v1-dropdown ' + (configSignal$()?.ui?.isFirst ? '' : 'remove-border')"
				panelStyleClass="sl-v1-dropdown-panel"
				optionLabel="label"
				appendTo="body"
				[options]="items"
				[showClear]="!configSignal$()?.ui.hideClear && !!selectedItems.length"
				[disabled]="disabled"
				[autoDisplayFirst]="false"
				[placeholder]="configSignal$()?.ui.placeholder"
				[ngModel]="selectedItems?.[0]"
				[filter]="configSignal$()?.ui.filter"
				(onClear)="clearFilter(true)"
				(onFilter)="searchControl.setValue($event.filter)"
				(onChange)="onSelectionChange($event, items)">
				<ng-template let-item pTemplate="item">
					<ng-container *ngTemplateOutlet="itemDisplay; context:{ item, items }"></ng-container>
				</ng-template>
				<ng-template pTemplate="clearicon">
					<i class="pi pi-times clearicon"></i>
				</ng-template>
				<ng-template pTemplate="dropdownicon">
					<i class="pi pi-angle-down"></i>
				</ng-template>
				<ng-template pTemplate="empty">
					<ng-container *ngTemplateOutlet="emptyMessage"></ng-container>
				</ng-template>
			</p-dropdown>
		}

			@case ('multiselect') {
				<p-multiSelect #multiselect
					styleClass="sl-v1-multiselect"
					panelStyleClass="sl-v1-multiselect-panel"
					optionLabel="label"
					[filter]="configSignal$().ui.filter"
					[showToggleAll]="true"
					[autofocusFilter]="true"
					[options]="items"
					[placeholder]="configSignal$().ui.placeholder"
					[(ngModel)]="selectedItems"
					[disabled]="disabled"
					(onClear)="clearFilter(true)"
					(onFilter)="searchControl.setValue($event.filter)"
					(onSelectAllChange)="onSelectAllChange($event, items)"
					(onChange)="onSelectionChange($event, items)">
					<ng-template let-item pTemplate="item">
						<ng-container *ngTemplateOutlet="itemDisplay; context:{ item, items }"></ng-container>
					</ng-template>
					<ng-template pTemplate="clearicon">
						<i class="pi pi-times clearicon"></i>
					</ng-template>
					<ng-template pTemplate="dropdownicon">
						<i class="pi pi-angle-down"></i>
					</ng-template>
					<ng-template pTemplate="empty">
						<ng-container *ngTemplateOutlet="emptyMessage"></ng-container>
					</ng-template>
				</p-multiSelect>
			}
		}
		</div>
	</div>
}

<ng-template #itemDisplay let-item="item" let-items="items">
	<div class="sl-typography-body sl-flexbox-row sl-flexbox-align-center item-label-wrapper sl-width-100"
		[class.dropdown-item]="configSignal$()?.ui?.displayMode === 'dropdown'"
		[class.selected]="!configSignal$()?.ui.multiSelect && selectedItems?.[0]?.value == item.value">

		<div class="filter-options sl-flexbox sl-width-100">
			<div class="filter-label ellipsisize sl-typography-body-medium pr-sm" tooltipStyleClass="sl-v1-tooltip" [pTooltip]="item.label">{{ item.label }}</div>
			@if (item.count) {
				<div class="filter-count sl-typography-body-medium">{{ item.count | number }}</div>
			}
			@if (configSignal$()?.ui?.displayMode === 'multiSelect' || configSignal$()?.ui?.multiSelect) {
				<span class="sl-typography-link-action only" (click)="selectOnly()">Only</span>
			}
			@if (configSignal$()?.ui?.actions?.length) {
				<span class="sl-flexbox sl-flexbox-align-center">
					@for (action of configSignal$()?.ui.actions; track action.id ) {
						<mat-icon
							class="material-symbols-outlined mat-icon-16 mr-sm"
							[pTooltip]="(action | actionPropertyResolver: item).tooltip"
							[tooltipPosition]="action.tooltipPosition"
							tooltipStyleClass="sl-v1-tooltip"
							[class.disabled]="(action | actionPropertyResolver: item).disabled"
							(click)="onActionClick($event, action, item)">
							{{ action.icon }}
						</mat-icon>
					}
				</span>
			}
		</div>
	</div>
</ng-template>

<ng-template #emptyMessage>
	@if (loadingSignal$()) {
		<i class="pi pi-spin pi-spinner"></i>
	} @else {
		No results found
	}
</ng-template>
