"use strict";

var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
var __spreadArray = this && this.__spreadArray || function (to, from) {
  for (var i = 0, il = from.length, j = to.length; i < il; i++, j++) to[j] = from[i];
  return to;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SlQueryRuleSetBuilder = exports.SlQueryRequestBuilder = void 0;
var sl_query_request_1 = require("./sl-query-request");
var sl_query_request_iterator_1 = require("./sl-query-request-iterator");
var SlQueryRequestBuilder = /** @class */function () {
  function SlQueryRequestBuilder() {
    this.setDefaults();
  }
  Object.defineProperty(SlQueryRequestBuilder.prototype, "ruleSetBuilder", {
    get: function () {
      return this.ruleSet;
    },
    enumerable: false,
    configurable: true
  });
  SlQueryRequestBuilder.prototype.withOffset = function (offset) {
    if (offset) {
      this.data.offset = offset;
    }
    return this;
  };
  SlQueryRequestBuilder.prototype.withLimit = function (limit) {
    if (limit) {
      this.data.limit = limit;
    }
    return this;
  };
  SlQueryRequestBuilder.prototype.withSort = function (sortBy, sortDirection) {
    if (sortBy && sortDirection) {
      this.data.sortBy.push(sortBy);
      this.data.sortDirection.push(sortDirection);
    }
    return this;
  };
  SlQueryRequestBuilder.prototype.withGlobalSearch = function (search) {
    if (search) {
      this.data.globalSearch = search;
    }
    return this;
  };
  SlQueryRequestBuilder.prototype.withRuleSet = function (ruleSet) {
    this.ruleSet = ruleSet;
    return this;
  };
  SlQueryRequestBuilder.prototype.withProjection = function (fields) {
    if (fields === null || fields === void 0 ? void 0 : fields.length) {
      this.data.projection = fields;
    }
    return this;
  };
  SlQueryRequestBuilder.prototype.withCountsFor = function (field) {
    if (field) {
      this.data.countBy = field;
    }
    return this;
  };
  SlQueryRequestBuilder.prototype.getAllRules = function (recursive) {
    var _a;
    if (recursive === void 0) {
      recursive = false;
    }
    return ((_a = this.ruleSet) === null || _a === void 0 ? void 0 : _a.getAllRules(recursive)) || [];
  };
  SlQueryRequestBuilder.prototype.findRules = function (field, recursive, required) {
    var _a, _b;
    if (recursive === void 0) {
      recursive = true;
    }
    if (required === void 0) {
      required = false;
    }
    if (required && ((_a = this.ruleSet) === null || _a === void 0 ? void 0 : _a.data.condition) === sl_query_request_1.Condition.Or) {
      return [];
    }
    return ((_b = this.ruleSet) === null || _b === void 0 ? void 0 : _b.findRules(field, recursive, required)) || [];
  };
  SlQueryRequestBuilder.prototype.deleteRules = function (field, operator, value) {
    var _a;
    (_a = this.ruleSet) === null || _a === void 0 ? void 0 : _a.deleteRules(field, operator, value);
  };
  SlQueryRequestBuilder.prototype.updateRules = function (field, operator, newValue, newOperator) {
    var _a;
    (_a = this.ruleSet) === null || _a === void 0 ? void 0 : _a.updateRules(field, operator, newValue, newOperator);
  };
  SlQueryRequestBuilder.prototype.done = function () {
    if (this.ruleSet) {
      this.data.ruleSet = this.ruleSet.done();
    }
    return this.data;
  };
  /**
   * return a flat object of all the fields that has '=' rule
   */
  SlQueryRequestBuilder.prototype.flat = function () {
    var res = {};
    var rules = this.findRules(undefined, true, true);
    rules.forEach(function (r) {
      res["" + r.field] = r.value;
    });
    return res;
  };
  SlQueryRequestBuilder.prototype.iter = function () {
    return new sl_query_request_iterator_1.SlQueryRequestIterator(this.done().ruleSet);
  };
  SlQueryRequestBuilder.prototype.setDefaults = function () {
    this.data = {
      limit: sl_query_request_1.DefaultPageSize,
      offset: 0,
      sortBy: [],
      sortDirection: []
    };
  };
  SlQueryRequestBuilder.buildPrivate = function (request, builder) {
    request.sortBy.forEach(function (s, i) {
      builder.withSort(s, request.sortDirection[i]);
    });
    builder.withGlobalSearch(request.globalSearch);
    builder.withLimit(request.limit);
    builder.withOffset(request.offset);
    builder.withProjection(request.projection);
    builder.withCountsFor(request.countBy);
    if (request.ruleSet) {
      builder.withRuleSet(builder.getNewRuleSetBuilder().buildRuleSet(request.ruleSet, builder));
    }
    return builder;
  };
  SlQueryRequestBuilder.build = function (request) {
    var builder = new SlQueryRequestBuilder();
    return SlQueryRequestBuilder.buildPrivate(request, builder);
  };
  SlQueryRequestBuilder.prototype.getDbBuilder = function (dbType, counter) {
    throw new Error('Not Supported For Current Compile Target');
  };
  SlQueryRequestBuilder.prototype.getNewRuleSetBuilder = function (condition) {
    return new SlQueryRuleSetBuilder(condition);
  };
  SlQueryRequestBuilder.createRuleSetBuilder = function (condition) {
    return new SlQueryRuleSetBuilder(condition);
  };
  return SlQueryRequestBuilder;
}();
exports.SlQueryRequestBuilder = SlQueryRequestBuilder;
var SlQueryRuleSetBuilder = /** @class */function () {
  function SlQueryRuleSetBuilder(condition) {
    if (condition === void 0) {
      condition = sl_query_request_1.Condition.And;
    }
    this.ruleSets = [];
    this.setDefaults(condition);
  }
  Object.defineProperty(SlQueryRuleSetBuilder.prototype, "isEmpty", {
    get: function () {
      return !this.data.rules.length && !this.ruleSets.length;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(SlQueryRuleSetBuilder.prototype, "ruleSetsBuilders", {
    get: function () {
      return this.ruleSets;
    },
    enumerable: false,
    configurable: true
  });
  SlQueryRuleSetBuilder.prototype.withRuleSet = function (ruleSet) {
    this.ruleSets.push(ruleSet);
    return this;
  };
  SlQueryRuleSetBuilder.prototype.withRule = function (field, operator, value, conversionType) {
    this.data.rules.push(this.buildRule(field, operator, value, conversionType));
    return this;
  };
  SlQueryRuleSetBuilder.prototype.getAllRules = function (recursive) {
    if (recursive === void 0) {
      recursive = false;
    }
    var rules = [];
    rules.push.apply(rules, __spreadArray([], __read(this.data.rules)));
    if (recursive) {
      this.ruleSets.forEach(function (rs) {
        rules.push.apply(rules, __spreadArray([], __read(rs.getAllRules(recursive))));
      });
    }
    return rules;
  };
  SlQueryRuleSetBuilder.prototype.findRules = function (field, recursive, required) {
    if (recursive === void 0) {
      recursive = true;
    }
    if (required === void 0) {
      required = false;
    }
    var rules = [];
    rules.push.apply(rules, __spreadArray([], __read(this.data.rules.filter(function (r) {
      return field ? r.field === field : r.operator === sl_query_request_1.Operator.Equals;
    }))));
    if (recursive) {
      this.ruleSets.forEach(function (rs) {
        if (required && rs.data.condition === sl_query_request_1.Condition.And) {
          rules.push.apply(rules, __spreadArray([], __read(rs.findRules(field, recursive, required))));
        }
      });
    }
    return rules;
  };
  SlQueryRuleSetBuilder.prototype.deleteRules = function (field, operator, value) {
    this.data.rules = this.data.rules.filter(function (r) {
      return !(r.field === field && (operator ? r.operator === operator : true) && (value !== undefined ? r.value === value : true));
    });
    this.ruleSets.forEach(function (rs) {
      rs.deleteRules(field, operator);
    });
  };
  SlQueryRuleSetBuilder.prototype.updateRules = function (field, operator, newValue, newOperator) {
    var idx = this.data.rules.findIndex(function (r) {
      return r.field === field && r.operator === operator;
    });
    if (idx > -1) {
      this.data.rules[idx].value = newValue;
      if (newOperator) {
        this.data.rules[idx].operator = newOperator;
      }
    }
    this.ruleSets.forEach(function (rs) {
      rs.updateRules(field, operator, newValue, newOperator);
    });
  };
  SlQueryRuleSetBuilder.prototype.done = function () {
    this.data.ruleSets = this.ruleSets.filter(function (rs) {
      return !rs.isEmpty;
    }).map(function (rs) {
      return rs.done();
    });
    return this.data;
  };
  SlQueryRuleSetBuilder.prototype.setDefaults = function (condition) {
    this.data = {
      condition: condition,
      rules: [],
      ruleSets: []
    };
  };
  SlQueryRuleSetBuilder.prototype.buildRuleSet = function (ruleSet, builder) {
    var slRuleSetBuilder = builder.getNewRuleSetBuilder(ruleSet.condition);
    ruleSet.rules.forEach(function (r) {
      var rule = slRuleSetBuilder.buildRule(r.field, r.operator, r.value, r.conversionType);
      slRuleSetBuilder.withRule(rule.field, rule.operator, rule.value, r.conversionType);
    });
    ruleSet.ruleSets.forEach(function (rs) {
      var rsBuilder = slRuleSetBuilder.buildRuleSet(rs, builder);
      slRuleSetBuilder.withRuleSet(rsBuilder);
    });
    return slRuleSetBuilder;
  };
  SlQueryRuleSetBuilder.prototype.buildRule = function (field, operator, value, conversionType) {
    switch (conversionType) {
      case sl_query_request_1.ConversionType.Date:
        return {
          operator: operator,
          field: field,
          value: new Date(value)
        };
      default:
        break;
    }
    return {
      operator: operator,
      field: field,
      value: value
    };
  };
  return SlQueryRuleSetBuilder;
}();
exports.SlQueryRuleSetBuilder = SlQueryRuleSetBuilder;
