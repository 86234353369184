import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { IslQueryRequest, SlQueryRequestBuilderWeb, SlQueryRuleSetBuilderWeb } from '@sealights/sl-query-builder';
import { catchError, map, Observable, of, switchMap } from 'rxjs';

import { APP_CONFIG } from '@@shared/providers/application-config-provider/application-config-provider.model';

import { SlLocalStorageService } from '../../local-storage/services/sl-local-storage.service';
import { filterData } from '../mocks/table-mock';
import { ApiConfig, DataSourceMode, FilterListOptionsResponse, getDefaultColumnStyle, SetupTableResponse, SLTableColumnDef, SLTableConfig, SLTableEmptyStateReason, SLTableIds, StringKeys, TableResponse } from '../models/sl-table.model';

@Injectable({
	providedIn: null,
})
export class SlTableService {
	readonly #httpClient = inject(HttpClient);
	readonly #apiBaseUrl = inject(APP_CONFIG)?.uri?.apiBaseUrl;
	readonly #localStorage = inject(SlLocalStorageService);

	constructor() { }

	setupTable<Req, Res>(config: SLTableConfig<Req, Res>): Observable<SetupTableResponse<Req, Res>> {
		return this.fetchData<Req, Res>(config.dataSourceConfig).pipe(
			switchMap(apiResponse => {
				const dataKeys = apiResponse.data.list.length > 0 ? this.createDataKeys(apiResponse.data.list) : [];
				const columns = this.#initializeColumns(dataKeys, config);
				this.#applyColumnDefsFromConfig(columns, config.columnConfig.columnDefs);
				this.#applyColumnDefsFromLS(columns, config.id);
				this.#applyColumnStyling(columns);
				columns.sort((a, b) => a.order - b.order);
				return of({ tableData: apiResponse, columns });
			}),
			catchError(err => {
				console.error('Error setting up table:', err);
				return of({ tableData: { data: { total: 0, next: 0, previous: 0, list: [] } }, columns: [] });
			})
		);
	}

	createDataKeys<Res>(data: Res[]): StringKeys<Res>[]  {
		return Array.from(
			data.reduce((keysSet, row) => {
				Object.keys(row).forEach(key => keysSet.add(key as StringKeys<Res>));
				return keysSet;
			}, new Set<StringKeys<Res>>())
		);
	}

	saveState(key: string, state: any): void {
		localStorage.setItem(key, JSON.stringify(state));
	}

	loadState<Req, Res>(key: string): SLTableColumnDef<Req, Res>[] {
		const state = localStorage.getItem(key);
		return state ? JSON.parse(state) as SLTableColumnDef<Req, Res>[] : null;
	}

	getEmptyStateReason<Req, Res>(query: IslQueryRequest<Req, Res>, tableDataResponse: TableResponse<Res>): SLTableEmptyStateReason {
		const hasActiveFilters = query.ruleSet.ruleSets.length > 0;
		const isDataEmpty = tableDataResponse?.data?.total === 0 && tableDataResponse?.data?.list.length === 0;
		return isDataEmpty ? (hasActiveFilters ? SLTableEmptyStateReason.NoDataDueToFilters : SLTableEmptyStateReason.NoData) : null;
	}

	loadLocalStorageData<Req, Res>(key: string, query: IslQueryRequest<Req, Res>): Observable<TableResponse<Res>> {
		const json = localStorage.getItem(key);
		const list = (json ? JSON.parse(json) : []) as Res[];
		return of(filterData({ data: { list } }, query));
	}

	fetchData<Req, Res>(dataSourceConfig: ApiConfig<Req, Res>, query?: SlQueryRuleSetBuilderWeb<Req, Res>,): Observable<TableResponse<Res>> {
		const url = `${this.#apiBaseUrl}${dataSourceConfig?.apiEndpoint}`;
		let res: Observable<TableResponse<Res>> = this.#httpClient.post<TableResponse<Res>>(url, query?.done() || dataSourceConfig.defaultQuery);

		if (dataSourceConfig.mode === DataSourceMode.LOCAL_STORAGE) {
			res = this.#localStorage.loadLocalStorageList(dataSourceConfig.localStorageKey, dataSourceConfig.defaultQuery);
		}

		return res
			.pipe(
				map((response: TableResponse<Res>) => dataSourceConfig.responseTransformer ?
					dataSourceConfig.responseTransformer(response) as TableResponse<Res>  :
					response
				),
				catchError(err => {
					console.error('Error fetching data:', err);
					return of({ data: { total: 0, next: 0, previous: 0, list: [] } });
				})
			);
	}

	fetchFilterData<Req, Res>(dataSourceConfig: ApiConfig<Req, Res>, query: SlQueryRequestBuilderWeb<Req, Res>): Observable<FilterListOptionsResponse> {
		const url = `${this.#apiBaseUrl}${dataSourceConfig?.apiEndpoint}`;

		return this.#httpClient.post(url, query.done())
			.pipe(
				map((response: Res) => dataSourceConfig.responseTransformer ?
					dataSourceConfig.responseTransformer(response) as FilterListOptionsResponse :
					response as FilterListOptionsResponse
				),
				catchError(err => {
					console.error('Error fetching data:', err);
					return of({ data: { totals: [] } });
				})
			);
	}

	keyToTitle(key: string): string {
		// Check if the string is already in Title Case
		if (/^(?:[A-Z][a-z]*\s)*[A-Z][a-z]*$/.test(key)) {
			return key; // Return the string as is if it's already in Title Case
		}
		// Convert camelCase to snake_case
		const modifiedKey = key.replace(/([A-Z])/g, '_$1').toLowerCase();
		// Replace any underscores or dashes with spaces, and split into words
		const words = modifiedKey.replace(/[_-]/g, ' ').split(' ');
		// Capitalize the first letter of each word and join them back into a string
		return words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
	}

	#initializeColumns<Req, Res>(dataKeys: (StringKeys<Res>)[], config: SLTableConfig<Req, Res>): SLTableColumnDef<Req, Res>[] {
		const cols: SLTableColumnDef<Req, Res>[] = [];
		if (dataKeys.length > 0) {
			for (let index = 0; index < dataKeys.length; index++) {
				const key = dataKeys[index];
				if (!config.columnConfig.ignoredColumns?.includes(key as string)) {
					cols.push({
						field: key,
						header: { displayName: this.keyToTitle(key as string) },
						isFilterable: true,
						isSortable: true,
						isSticky: false,
						isResizable: true,
						isVisible: true,
						isToggable: true,
						order: index,
						metaData: null,
						style: null
					});
				}
			}
		}
		return cols;
	}

	#applyColumnStyling<Req, Res>(cols: SLTableColumnDef<Req, Res>[]): void {
		const visibleColumns = cols.filter(col => !col.isSticky && col.isVisible && !col.isDisabled);
		visibleColumns.forEach(col => {
			col.style = getDefaultColumnStyle(visibleColumns);
		});
	}

	#applyColumnDefsFromConfig<Req, Res>(cols: SLTableColumnDef<Req, Res>[], columnDefs: SLTableColumnDef<Req, Res>[]): void {
		columnDefs?.forEach(colDef => {
			const displayColumn = cols.find(col => col.field === colDef.field);
			if (displayColumn) {
				Object.assign(displayColumn, colDef);
			}
		});
	}

	#applyColumnDefsFromLS<Req, Res>(cols: SLTableColumnDef<Req, Res>[], tableId: SLTableIds): void {
		const savedColumns = this.loadState<Req, Res>(tableId);
		if (!savedColumns?.length) {
			return;
		}
		this.#applyColumnDefsFromConfig(cols, savedColumns);
	}
}
